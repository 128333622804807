import * as $ from "jquery";
import { UlsyUtil } from "./utils";
import { UlsyDropdown } from "./dropdown";

export class UlsyMobileMenu  {
  hamburger : JQuery;
  menuNav : JQuery;
  ulsyUtil : UlsyUtil;
  constructor(){
    this.ulsyUtil = new UlsyUtil();
    this.hamburger = $('.hamburger');
    this.menuNav = $('nav.top-bar__menu'); 
    if(!this.init()){
      console.error({
       message: 'Mobile menu cannot initialize',
       object: this
      });
    }

  }

  show(){
    this.hamburger.addClass('is-active');
    this.menuNav.addClass('is-active');
    return true;
  };

  hide(){
    this.hamburger.removeClass('is-active');
    this.menuNav.removeClass('is-active');
    return true;
  };

  hamburgerClassToggle(){
    if(this.hamburger.hasClass('is-active')){
      this.hide();
    }else{
      this.show();
    }
  }

  setMobileMenu(){
    if(window.matchMedia('(max-width:960px)').matches){
      this.menuNav.addClass('mobile-menu-nav');  
    }else{
      this.menuNav.removeClass('mobile-menu-nav');
    }
    this.hide();
  }

  init(){
    let self = this;
    this.hamburger.on('click', function(){
      self.hamburgerClassToggle();
    });
    
    let setMobileMenu = self.ulsyUtil.debounce( function(){
      self.setMobileMenu();
    }, 200);

    $(window).on('resize', setMobileMenu);
    self.setMobileMenu();
    return true;
  };
}