import * as $ from 'jquery';


export class UlsyScrollParalax {
  element : JQuery;
  paralaxEffect : number;

  constructor(element : string, paralaxEffect:number = 10){
    this.element = $(element);
    this.paralaxEffect = paralaxEffect;
    if(this.element.length > 0 && window.matchMedia('(min-width:960px)').matches){
      this.watch();
    }
  }

  watch(){
    let self = this;
    $(window).on('scroll', function (){
      let pagePercentageScroll = self.getPagePercentageScroll();
      self.element.each(function(){
        let elementY = self.getElementTransformY($(this));
        self.setElementTransformY($(this), self.getPagePercentageScroll() * self.paralaxEffect);          
      });
    });
  }

  getElementTransformY(element:JQuery){
    var matrix = element.css('transform');
    var values = matrix.match(/-?[\d\.]+/g);
    var y = values[5];    
    return y; 
  }

  setElementTransformY(element : JQuery, value : number){
    element.css('transform', 'translateY('+value+'px)');
  }

  getPagePercentageScroll(){
    return 100 * $(window).scrollTop() / ($(document).height() - $(window).height());
  }

  getElementBottomPosition(element : JQuery){
    return element.position().top + element.outerHeight(true);
  }
}