import * as $ from 'jquery';
import {} from 'googlemaps';

export class UlsyContactMap {

  public _default : any = {
    googleMapsUrl : 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDU1T9Z3_00BrilXBWGOeSC9mZUMM7us4A',
    selector : "contact-map",
    markerIcon : {}
  }

  public map : google.maps.Map;

  constructor(params : any = {}) {
    let self = this;
    if(params !== {}){
      for(var prop in params){
        if(self._default[prop]){
          self._default[prop] = params[prop];
        }else{
          throw "There is no such parameter in _defualt : "+prop;
        }     
      }
    }
  }

  public setupMap(){
    var self = this;
    this.map = new google.maps.Map(document.getElementById(this._default.selector), {
      center: new google.maps.LatLng(50.044851, 19.963400),
      zoom: 15,
      styles: [
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
              {
                  "color": "#c4c4c4"
              },
              {
                  "lightness": 5
              }
          ]
        },
        {
          "featureType": "landscape",
          "elementType": "geometry",
          "stylers": [
              {
                  "color": "#f5f5f5"
              },
              {
                  "lightness": 20
              }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.fill",
          "stylers": [
              {
                  "color": "#ffffff"
              },
              {
                  "lightness": 17
              }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
              {
                  "color": "#ffffff"
              },
              {
                  "lightness": 29
              },
              {
                  "weight": 0.2
              }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "geometry",
          "stylers": [
              {
                  "color": "#ffffff"
              },
              {
                  "lightness": 18
              }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "geometry",
          "stylers": [
              {
                  "color": "#ffffff"
              },
              {
                  "lightness": 16
              }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
              {
                  "color": "#f5f5f5"
              },
              {
                  "lightness": 21
              }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
              {
                  "color": "#dedede"
              },
              {
                  "lightness": 21
              }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
              {
                  "visibility": "on"
              },
              {
                  "color": "#ffffff"
              },
              {
                  "lightness": 16
              }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "saturation": 36
              },
              {
                  "color": "#333333"
              },
              {
                  "lightness": 40
              }
          ]
        },
        {
          "elementType": "labels.icon",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "geometry",
          "stylers": [
              {
                  "color": "#f2f2f2"
              },
              {
                  "lightness": 19
              }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "geometry.fill",
          "stylers": [
              {
                  "color": "#fefefe"
              },
              {
                  "lightness": 20
              }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "geometry.stroke",
          "stylers": [
              {
                  "color": "#fefefe"
              },
              {
                  "lightness": 17
              },
              {
                  "weight": 1.2
              }
          ]
        }
      ] 
    });

    

    this._default.markerIcon = {
        url: '../front/assets/marker.png',
        scaledSize: new google.maps.Size(50, 64),
        origin: new google.maps.Point(0,0), // origin
        anchor: new google.maps.Point(25, 55) // anchor
    }

    let markerPos = new google.maps.LatLng(50.044545, 19.963496);
    
    var marker = new google.maps.Marker({
      position: markerPos,
      map: this.map,
      icon : this._default.markerIcon
    });

    var contentString = `<div class="infowindow">
        <p>Ultimate Systems Sp. z o.o. <br>
        ul. Zaułek 4a <br>
        30-703 Kraków <br>
        <br>
        <a href="mailto:info@ultimate.systems">info@ultimate.systems</a><br>
        <a href="tel:+48 792 237 315 ">tel. +48 792 237 315 </a> </p>
    
    </div> `;

    var infowindow = new google.maps.InfoWindow({
        content: contentString
    });

    marker.addListener('click', function() {
        infowindow.open(self.map, marker);
    });

  }

  public init(){
    if(this.isSelectorElementOnPage()){
      this.addMapScript();
    }else{
      throw "There is no such selector on this site selector: "+this._default.selector;
    }
  }

  public isSelectorElementOnPage(){
    return $(this._default.selector);
  }

  public addMapScript(){
    var googleMapsUrl = this._default.googleMapsUrl;
    if (!this.isGoogleMapsDefined(googleMapsUrl)) { 
      document.body.appendChild(Object.assign(
        document.createElement('script'), {
          type: 'text/javascript',
          src: googleMapsUrl,
          onload: () => this.setupMap()
        }));
    } else {
      this.setupMap();
    }
  }

 public isGoogleMapsDefined(url : string){
    return document.querySelectorAll(`[src="${url}"]`).length;
 }
}