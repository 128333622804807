import * as $ from 'jquery';
import "jquery-validation";

export class UlsyEstimate {
  _default : any = {
    activeCategory: "",
    categoryBoxClass : '.estimate-case',
    categoryAttr : "category",
    nextStepButton : '.estimate__button',
    prevStepButton : '.estimate__prev-button',
    steps: '.linkSteps',
    caseClass : '.estimate__case',
    forms: '#estimate-form, #brief-form',
    sendClass : '#send-estimate', 
    sendUrl : window.location.href
  }

  lastStep : number = 1;
  activeStep : number = 1;
  maxStep : number = 1;

  constructor(params : any){
    let self = this;
    if(params !== {}){
      for(let prop in params){
        if(self._default[prop]){
          self._default[prop] = params[prop];
        }else{
          throw "There is no such parameter in _defualt : "+prop;
        }     
      }
    }
    this.setPreventSubmitForm();
    this.setValidation(this._default.forms);
    this.setCategoryClick();
    this.setNextStepButton();
    this.setSwitchStepButton();
  }

  setPreventSubmitForm(){
    $(this._default.forms).on('submit', (e) => {e.preventDefault()})
  }

  setValidation(form : string){
    let self = this;
    ($('#estimate-form') as any).validate({
      submitHandler : function(){
        self.sendData();
      }
    })
  }

  sendData(){
    let self = this;
    let data = self.collectData();
    $.ajax({
      url: self._default.sendUrl,
      method: "POST",
      data: data,
      success: function(result){
        if(result.success){
          self.nextStep();
        }
      },
      error : function(error){
        console.error(error);
      }
      
    });
  }

  collectData(){
    let self = this;
    let data : {[k : string] : any} = {};
     data['category'] = this._default.activeCategory;
    
    $.each($(self._default.forms).serializeArray(), function(_, kv) {
      data[kv.name] = kv.value;
    });

    return data;
  }

  setCategoryClick(){
    let self = this;
    $(this._default.categoryBoxClass).on('click', function(){
      $('.estimate-case').removeClass('error')
      $('.estimate__content').find('.estimate__description.error').addClass('hide-error')
      let element = $(this);
      let category = element.attr('category');
      if(self.isCategory(category)){
        self.disableCategory(element, category);       
      }else{
        self.enableCategory(element, category);
      }

      console.log(self._default.activeCategory);
    });
  } 

  enableCategory(element : JQuery, category : string){
    $(element).addClass('active');
    this.addCategory(category);
  }
  
  addCategory(category : string){
    if(this._default.activeCategory != ""){
      this._default.activeCategory += ", "+category; 
    }else{
      this._default.activeCategory = category;
    }
    
  }

  disableCategory(element : JQuery, category : string){
    $(element).removeClass('active');
    this.removeCategory(category);
  }

  isCategory(category : string){
    return (this._default.activeCategory.indexOf(category) !== -1);
  }

  removeCategory(category : string){
    this._default.activeCategory  = this._default.activeCategory.replace(", "+category, "");
    this._default.activeCategory  = this._default.activeCategory.replace(category+", ", "");
    this._default.activeCategory  = this._default.activeCategory.replace(category, "");
  }

  setNextStepButton(){
    let self = this;

    $(this._default.nextStepButton).on('click', function(e){
      e.preventDefault();
      let element = $(this);
      let goahead = true;
      if(self.activeStep == 1){
        console.log("step 1")
        console.log($('.estimate-case.active'))
        if ($('.estimate-case.active').length > 0)
        {
          goahead = true
        }else {
          goahead = false
          $('.estimate-case').addClass('error')
          $('.estimate__content').find('.estimate__description.error').removeClass('hide-error')
        }
      }
      if(self.activeStep == 2){
        console.log("step 2")
        self.setRequiredToLastTexarea();
      }
      console.log(goahead)
      goahead == true ? self.nextStep() : ''
    });
  }

  setPrevStepButton(){
    let self = this;
    $(this._default.prevStepButton).addClass('active');
    
    $(this._default.prevStepButton).unbind('click');
    $(this._default.prevStepButton).on('click', function(e){
      e.preventDefault();
      self.prevStep();
    });
  }

  setSwitchStepButton(){
    let self = this;

    $(".menu__list-item").hover(function(){
      $(this).find('.hover-dropdown-menu').show();
    }, function(){
      $(this).find('.hover-dropdown-menu').hide();
    });

    // $(this._default.steps).hover(function(e) {
    //   e.preventDefault();
    //   let self = $(this);
    //
    //   if (self.hasClass('step1')) {
    //     $('.estimate__steps').find('img').attr('src', '/front/assets/steps/step1.svg');
    //   } else if (self.hasClass('step2')) {
    //     $('.estimate__steps').find('img').attr('src', '/front/assets/steps/step2.svg');
    //   } else if (self.hasClass('step3')) {
    //     $('.estimate__steps').find('img').attr('src', '/front/assets/steps/step3.svg');
    //   }
    // }, function () {
    //   if ($('.estimate__case--1').hasClass('active')) {
    //     $('.estimate__steps').find('img').attr('src', '/front/assets/steps/step1.svg');
    //   }else if ($('.estimate__case--2').hasClass('active')) {
    //     $('.estimate__steps').find('img').attr('src', '/front/assets/steps/step2.svg');
    //   }else if ($('.estimate__case--3').hasClass('active')) {
    //     $('.estimate__steps').find('img').attr('src', '/front/assets/steps/step3.svg');
    //   }
    // });

    $(this._default.steps).on('click', function (e) {
      e.preventDefault();
      var self = $(this);

      if (self.hasClass('step1')) {
        if ($('.estimate__case--2').hasClass('active')) {
          $('.estimate__prev-button').trigger('click');
        }else if ($('.estimate__case--3').hasClass('active')) {
          $('.estimate__prev-button').trigger('click');
          $('.estimate__prev-button').trigger('click');
        }
      }else if(self.hasClass('step2')) {
        if ($('.estimate__case--1').hasClass('active')) {
          $('.estimate__case--1').find('.estimate__button').click();
        }else if ($('.estimate__case--3').hasClass('active')) {
          $('.estimate__prev-button').trigger('click');
        }
      }else if(self.hasClass('step3')) {
        if ($('.estimate__case--1').hasClass('active')) {
          $('.estimate__case--1').find('.estimate__button').click();
          $('.estimate__case--2').find('.estimate__button').click();
          //$('.estimate__button').trigger('click');
        }else if ($('.estimate__case--2').hasClass('active')) {
          $('.estimate__case--2').find('.estimate__button').click();
        }
      }
    });
  }

  nextStep(){
    this.lastStep = this.activeStep;
    this.activeStep++;
    this.activateStep(this.activeStep);
  }

  prevStep(){
    if(this.activeStep > 1){
      this.lastStep = this.activeStep;
      this.activeStep--;
      this.activateStep(this.activeStep);  
    }
  }

  activateStep(step : number){
    let self = this;
    let activeClass = self._default.caseClass + "--" + self.activeStep;
    let stepImg = $('.estimate__steps img');
    let stepImgSrc = stepImg.attr('src');

    stepImg.attr('src', stepImgSrc.replace(this.lastStep.toString(), this.activeStep.toString()));
    
    $(this._default.caseClass).each(function () {
      let element  = $(this);
      if(element.hasClass( activeClass.replace('.', '') )){

        element.addClass('active');
      }else{
        element.removeClass('active');
      }
    });

    $('.linkSteps').removeClass('active');
    $(`.step${self.activeStep}`).addClass('active')
    self.activeStep == 2 ? $(`.step1`).addClass('active') : ''
    if (self.activeStep == 3) {
      $(`.step1`).addClass('active')
      $(`.step2`).addClass('active')
    }

    if(this.activeStep === 4){
      $('.estimate__header').css('opacity', 0);
      $('.estimate__prev-button').removeClass('active');
    }

    this.checkPrevButton();
  }

  checkPrevButton(){
    if(this.activeStep > 1){
      this.setPrevStepButton();
    }else{
      $(this._default.prevStepButton).removeClass('active');
    }
  }

  setRequiredToLastTexarea(){
    let element = $('#short-description-field');

    if(this.isBriefFilled()){
      element.prop('required', false);
    }
  }

  isBriefFilled(){
    let isFilled = false;
    $('#brief-form .estimate-form__input').each(function(){
      if($(this).val() !== "") isFilled = true;
    });
    return isFilled;
  }
}