import * as $ from 'jquery';


export class UlsyScrollAnimation {
  Elements : JQuery;
  scrollPrecision : number;

  constructor(elementsSelector : string, scrollPrecision : number){
    this.Elements = $(elementsSelector);
    this.scrollPrecision = scrollPrecision;
    this.watchScroll();
  }

  hide(element : JQuery){
    element.removeClass('active');
  }

  show(element : JQuery){
    element.addClass('active');
  }

  watchScroll(){
    let self = this;
    if(self.Elements.length > 0){
      $(window).scroll(function(){
        self.Elements.each(function(index, element){
          if(self.isScrolledIntoView($(this))){
           self.show($(this));
          } 
        });
      });
    }
  }

  isScrolledIntoView(elem : JQuery)
  {
      var docViewTop = $(window).scrollTop();
      var docViewBottom = docViewTop + $(window).height();
  
      var elemTop = $(elem).offset().top;
      var elemBottom = elemTop + $(elem).height();
  
      return ((elemBottom + this.scrollPrecision) <= docViewBottom && (elemTop - this.scrollPrecision) >= docViewTop );
  }
}