import * as $ from 'jquery';
import { UlsyOverHeader } from "./over-header";
import {UlsyUtil} from "./utils";


export class UlsyStickyTopBar {
  
  topBarClass : string;
  topBarObject : JQuery;
  topBarToHeightObject : JQuery;
  bodyElement : JQuery;
  overHeader : UlsyOverHeader;

  overHeaderHeight : number;

  topbarHeight : number;
  windowScroll : number;

  watchFunction : any;



  constructor(topBarSelector : string , toHeightSelector : string){
    this.topBarClass = topBarSelector;
    this.topBarObject = $(this.topBarClass);
    this.bodyElement = $('body');
    this.topBarToHeightObject = $(toHeightSelector);
    this.windowScroll = window.pageYOffset || document.documentElement.scrollTop;
    this.Init();
  }

  Init(){
    let self = this;
    let util = new UlsyUtil();
    this.SetSticky();
    this.WatchWindowScroll();
  }

  TurnOffWatch(){
  }

  WatchWindowScroll(){
    let self = this;
    $(window).scroll(function(e){
      self.windowScroll = window.pageYOffset || document.documentElement.scrollTop;
    
      self.SetSticky();
    });
  }

  EnableSticky(){
    if(!this.topBarObject.hasClass('sticky')){
      this.topBarObject.addClass('sticky');
      this.topBarObject.find('.top-bar__header').addClass('sticky');
      this.bodyElement.css('margin-top', this.topbarHeight);  
      $(window).scrollTop(1);
    }
  }

  DisableSticky(){
    if(this.topBarObject.hasClass('sticky')){
      this.topBarObject.removeClass('sticky');
      this.topBarObject.find('.top-bar__header').removeClass('sticky');
      this.bodyElement.css('margin-top', 0);
      $(window).scrollTop(this.overHeaderHeight);
    }
  }

  SetSticky(){
    var self = this;
    if(self.windowScroll <= 0){
      self.DisableSticky();
    }else{
      self.EnableSticky();
    }
  }

  IsSticky(){
    return this.topBarObject.hasClass('sticky');
  }
}