import * as $ from 'jquery';
import "jquery-validation";
import * as Swiper from 'swiper';
import { UlsyDropdownButton } from "./lib/dropdown-button";
import { UlsyServicesButton } from "./lib/services-dropdown-button";
import { UlsyTimingSwiper } from "./lib/timing-pagination-swiper";
import { UlsyStickyTopBar } from "./lib/sticky";
import { UlsyOverHeader } from "./lib/over-header";
import { UlsyMobileMenu } from "./lib/mobile-menu";
import { UlsyScrollAnimation } from "./lib/scroll-animations";
import { UlsyScrollParalax } from "./lib/scroll-paralax";
import { UlsyContactMap } from "./lib/map";
import { UlsyEstimate } from "./lib/estimate";
  
$(function(){
  init();
  //sierotki();
})

function init(){
  let languageButton = new UlsyDropdownButton('#top-bar-language-button .language-button', "#top-bar-language-button .language-button .language-button__element");
  let socialButton = new UlsyDropdownButton('#top-bar-social-button .social-button', "#top-bar-social-button .social-button .social-button__element");
  let servicesButton = new UlsyServicesButton('#services-button .services-button', "#services-button .services-button .services-button__element");
  let caseStudyButton = new UlsyServicesButton('#case-study-button .case-study-button', "#case-study-button .case-study-button .case-study-button__element");
  if(window.matchMedia('(min-width:500px)').matches){
    let bannerSwiper = new UlsyTimingSwiper( 4000 );
  }
  
  // let clientsSlider = new Swiper('#clients-slider', {
  //   speed: 400,
  //   spaceBetween:100,
  //
  //   slidesPerView: 4,
  //   loop: true,
  //   autoplay: 2500,
  //   autoplayDisableOnInteraction: true,
  //   pagination: ".clients__pagination",
  //   paginationClickable: true,
  //
  //   nextButton: ".clients__button--next",
  //   prevButton: ".clients__button--prev",
  //   breakpoints: {
  //     959: {
  //       spaceBetween: 20
  //     },
  //     650:{
  //       slidesPerView:3
  //     },
  //     450: {
  //       slidesPerView:2
  //     }
  //   }
  //
  // })
  let stickyHeader = new UlsyStickyTopBar('.top-bar', '.top-bar__header');
  let mobileMenu = new UlsyMobileMenu();

  let svgScrollAnimations = new UlsyScrollAnimation('.svg-draw', 20);
  let boxScrollAnimations = new UlsyScrollAnimation('.process-step__header, .process-step__content, .process-step__side', 20);
  let overFooterAnimations = new UlsyScrollAnimation('.contact__item, .about-item__title, .about-item__content', 20);
  let serviceSneakPeakAnimation = new UlsyScrollAnimation('.service-sneakpeak', -300);

  let scrollParalax  = new UlsyScrollParalax('.site-background:not(.reverse)');
  let scrollParalaxRight  = new UlsyScrollParalax('.site-background.reverse', -10);
  
  if(window.location.href.indexOf('kontakt') !== -1 || window.location.href.indexOf('contact') !== -1) {
    let map = new UlsyContactMap();
    map.init();
  }

  let estimate = new UlsyEstimate({});



  $('.service-sneakpeak').hover(function(){
    $(this).find('.service-sneakpeak__hover').toggleClass("active");
  });

  $('.top-bar .service-sneakpeak__hover').hover(function(){
    $(this).toggleClass("active");
  })

  $.extend(($ as any).validator.messages, {
    required: "To pole jest wymagane.",
    remote: "Zmień wartość tego pola.",
    email: "Wpisz poprawny adress email.",
    url: "Wpisz poprawny adres URL.",
    date: "Wpisz poprawną datę.",
    dateISO: "Please enter a valid date (ISO).",
    number: "Please enter a valid number.",
    digits: "Please enter only digits.",
    creditcard: "Please enter a valid credit card number.",
    equalTo: "Please enter the same value again.",
    accept: "Please enter a value with a valid extension.",
    maxlength: ($ as any).validator.format("Please enter no more than {0} characters."),
    minlength: ($ as any).validator.format("Please enter at least {0} characters."),
    rangelength: ($ as any).validator.format("Please enter a value between {0} and {1} characters long."),
    range: ($ as any).validator.format("Please enter a value between {0} and {1}."),
    max: ($ as any).validator.format("Please enter a value less than or equal to {0}."),
    min: ($ as any).validator.format("Please enter a value greater than or equal to {0}.")
  });

  ($('.contact-form') as any).validate({})

  $('.point-circle-button-effect, .point-circle-button-effect--dark, .point-circle-button-effect--blue')
    .on('mouseenter', function(e) {
      var parentOffset = $(this).offset(),
          relX = e.pageX - parentOffset.left,
          relY = e.pageY - parentOffset.top;
      $(this).find('span').css({top:relY, left:relX})
    })
    .on('mouseout', function(e) {
      var parentOffset = $(this).offset(),
          relX = e.pageX - parentOffset.left,
          relY = e.pageY - parentOffset.top;
      $(this).find('span').css({top:relY, left:relX})
    });

  $('.form-article__button, .contact-form__button').on('click', function(e){
    e.preventDefault();
    let forAttr = $(this).attr('for');
    let formSubmit = $(forAttr);

    formSubmit.trigger('click');

  });

  $('.banner-slide__button').on('click', function(e){
    e.preventDefault();

    $('html, body').animate({
      scrollTop: $("#services").offset().top - 190
  }, 2000);
  });

  $('#choose-button').on('click', function(e){
    e.preventDefault();
    var slug =  $('#services-button .services-button__list-item.active .services-button__element').attr('slug');
    window.location.href = $(this).attr('offer-url').replace('__SLUG__', slug);
  });

  $('.estimate-form__textarea').on('focus', function(){
    $(this).addClass('active');
  }).on('blur', function(){
    if($(this).val() == ""){
      $(this).removeClass('active');
    }
  });

  function win_width()
  {
    if($(document).width()<960)
    {
      console.log( $('.languageMain').find(".top-bar__menu-anchor").text())
      //$('.languageMain').find(".top-bar__menu-anchor").html('PL')
    }else{
    }
  }
  win_width()
  $(window).on('resize', function()
  {
    win_width();
  });
}

function sierotki(){
  console.log('sierotki');
  $('section p, section span, h1, h2, h3, h4, .service-sneakpeak__description p').each(function(){
    if($(this).length > 0) {
      var $html = $(this).html();
      $html = $html.replace(/(\s)([\S])[\s]+/g, "$1$2&nbsp; ");
      $html = $html.replace(/(\s)([^<][\S]{1})[\s]+/g,"$1$2&nbsp; ");
      $html = $html.replace(/(\s)([^<][\S]{2})[\s]+/g,"$1$2&nbsp; ");
        $(this).empty().html($html);
    } 
  });
}