import * as $ from 'jquery';
import { UlsyDropdown } from "./dropdown";

export class UlsyDropdownButton extends UlsyDropdown {
  
  button : JQuery;
  buttonElement: JQuery;
  form : JQuery;
  
  constructor( buttonSelector : string, buttonElement: string ){
    super();
    let self = this;
    this.button = $(buttonSelector);
    this.buttonElement = $(buttonElement);
    this.isOpen = false;
    this.buttonElement.on('click', function(e){
      if($(this).hasClass('active')){
        e.preventDefault();
        self.toggle();   
      }
    });
  }
  
  hide(){
    this.button.removeClass("opened");    
    this.button.addClass('closed');
    this.isOpen = false;
    return true;
  }

  init(){
    return true;
  }

  show(){
    this.button.removeClass("closed");
    this.button.addClass('opened');
    this.isOpen = true;
    return true;
  }

  toggle(){
    if(this.isOpen){
      return this.hide();
    }else{
      return this.show();
    }
  }
}