import * as $ from 'jquery';
import * as Swiper from 'swiper';

export class UlsyTimingSwiper {
  public swiper : Swiper;
  public myCanvas : any;
  public isMultipleSlide : boolean;
  private ctx : any;
  private autoplay : number;

  private width : number;
  private height : number;
  private posX : number;
  private posY : number;
  private radius : number;
  private start : any;
  private lastCalledTime : number;
  private time : any; 
  private currentValue : any;
  private autoplayTime : number;
  private finish : any; 
  private circum : any; 

  constructor(autoplayTime : number){
    let self = this;
   
    self.autoplayTime = autoplayTime;
    self.myCanvas  = document.getElementById("myCanvas");
    
    self.isMultipleSlide =  ($('.page-banner__swiper-container .swiper-slide').length > 1);
    if(self.myCanvas !== null && self.isMultipleSlide){
      self.myCanvas.style = "display:block";
      // $(self.myCanvas).css('display', "block");
      // self.SetCtx();
      this.ctx = this.myCanvas.getContext('2d');
      this.ctx.lineWidth = 4;
      this.ctx.strokeStyle = "#012f80";
      this.ctx.shadowOffsetX = 0;
      this.ctx.shadowOffsetY = 0;
      this.ctx.shadowBlur = 0;
      this.ctx.shadowColor = "#012f80";
      self.width = self.myCanvas.width;
      self.height = self.myCanvas.height;
      
      //Canvas values
      self.posX = self.width / 2,
      self.posY = self.height / 2;
      self.radius = 32;
      self.circum = Math.PI * 2,
      self.start = 0,
      self.finish = 100,
      self.currentValue = 0;
      
      self.lastCalledTime = 0;
      self.time = 0.0;

      self.swiper = new Swiper('.page-banner__swiper-container', {
        speed: 400,
        spaceBetween: 0,
        slidesPerView: 1,
        // loop: true,
        autoplay : self.autoplayTime,
        pagination: '.banner-slide__pagination',
        paginationClickable: true,
        onSlideChangeStart: function (swiper) {
          self.UploadLoader()
          $('.page-image__image').removeClass('active');
          $('.page-image__image--'+(swiper.activeIndex+1)).addClass('active');
        }
      });
      self.UploadLoader();

    }else{
      $(self.myCanvas).css('display', "none");
    }
  }

  UploadLoader(){
    var myCanvas = document.getElementById("myCanvas");
    $('.banner-slide__pagination .swiper-pagination-bullet-active').append(myCanvas);
    this.clearVariables();
    this.animate(100);
  }

    
  animate(draw_to : any) {
    let self = this;
    self.ctx.clearRect(0, 0, self.width, self.height);
    self.ctx.beginPath();
    self.ctx.arc(self.posX, self.posY, self.radius, self.start, draw_to, false);
    self.ctx.stroke();

    if(!self.lastCalledTime){
      self.lastCalledTime = Date.now();
    }
    let delta = (Date.now() - self.lastCalledTime)/1000;
 
    self.lastCalledTime = Date.now();

    self.time += delta;


    self.currentValue += (100 / (self.autoplayTime / 1000)  ) * delta;

    if (self.currentValue < self.finish + 1) {
        requestAnimationFrame(function() {
          self.animate(self.circum * self.currentValue / 100 + self.start);
        });
    }
  }

  clearVariables ( ){
    this.posX = this.width / 2,
    this.posY = this.height / 2,
    this.radius = 32,
    this.circum = Math.PI * 2,
    this.start = Math.PI * 0,
    this.finish = 100,
    this.currentValue = 0;
    this.lastCalledTime = 0;
    this.time = 0;
  }
}